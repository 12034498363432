import { ref, watch, computed } from '@vue/composition-api'
import AppUtils from '@/auth/jwt/useJwt'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function getTransactionsList() {
  // Use toast
  const toast = useToast()

  const refTxnListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'receiptNo', label: 'Receipt No', sortable: true },
    { key: 'module', label: 'Module', sortable: true },
    { key: 'paymentMode', label: 'Payment Mode', sortable: true },
    { key: 'service', label: 'Service', sortable: true },
    {
      key: 'amount', label: 'Amount', sortable: true, formatter: val => `${val}`,
    },
    {
      key: 'date', label: 'Transaction Date', sortable: true, formatter: val => `${val.substr(0, 10)}`,
    },
  ]
  const perPage = ref(10)
  const allTransactions = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('date')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refTxnListTable.value ? refTxnListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: allTransactions.value,
    }
  })

  const refetchData = () => {
    refTxnListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchTransactions = (ctx, callback) => {
    AppUtils.fetchTransactions({
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
    })
      .then(response => {
        callback(response.data.data)
        allTransactions.value = response.data.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching transactions list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchTransactions,
    tableColumns,
    perPage,
    currentPage,
    allTransactions,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTxnListTable,

    refetchData,
  }
}
